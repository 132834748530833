import { InputPassword } from '@f/core/forms/inputs'
import { Form } from '@f/core/forms/Form'
import { required } from 'utils/inputs/validation'
import { getPasswordRules } from 'utils/inputs/passwordRules'
import { Rule } from '@f/core/forms/inputs/InputPassword'
import ChangePassword from '~/shop/front/components/userProfile/ChangePassword'
class ChangePasswordForm {
	status = ''
	processing = false
	form = false
	component = ChangePassword

	constructor (app) {
		this.app = app
	}

	get translations () {
		return this.app.translator.get('forms.clientChangePassword')
	}

	async init () {
		await this.app.translator.prefetch('errors')
		await this.app.translator.prefetch('forms.clientChangePassword')

		this.form = new Form()
		this.form.addInput(InputPassword.create({
			translations: () => this.app.translator.get('forms.clientChangePassword.inputOldPassword'),
			attribute: 'oldPassword',
			name: 'oldPassword',
			validation: [required()],
			rules: []
		}))
		this.form.addInput(InputPassword.create({
			translations: () => this.app.translator.get('forms.clientChangePassword.inputNewPassword'),
			attribute: 'password',
			name: 'password',
			validation: [required()],
			rules: getPasswordRules(Rule, this.app)
		}))
	}

	async changePassword () {
		if (!await this.form.validate()) return false
		try {
			this.processing = true

			const body = this.form.getValues()

			const { error, alias } = await this.app.getService('rext').userChangePassword(body)

			this.processing = false

			if (error) {
				this.status = {
					message: this.translations.status[alias],
					code: 'error'
				}
				return
			}
			this.status = {
				message: this.translations.status.SUCCESS,
				code: 'correct'
			}
			this.processing = false
		} catch (err) {
			console.error(err)
			this.status = {
				message: this.translations.status.SERVER_ERROR,
				code: 'error'
			}
		}
	}
}
export {
	ChangePasswordForm
}
